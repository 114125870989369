import React, { useEffect, useState } from "react";
import { Menu, Dropdown, Avatar, message } from "antd";
import { connect } from "react-redux";
import {
	EditOutlined,
	// SettingOutlined,
	LogoutOutlined,
	UserOutlined,
} from "@ant-design/icons";
import Icon from "components/util-components/Icon";
import { signOut } from "redux/actions/Auth";
import adindexService from "services/AdindexService";
import jwtDecode from "jwt-decode";
import { AUTH_TOKEN } from "redux/constants/Auth";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const menuItem = [
	{
		title: "Edit Profile",
		icon: EditOutlined,
		path: `${APP_PREFIX_PATH}/account`,
	},
	// {
	//   title: "Account Setting",
	//   icon: SettingOutlined,
	//   path: `${APP_PREFIX_PATH}/account/information`
	// }
];

export const NavProfile = ({ signOut }) => {
	const [profile1stLine, setProfile1stLine] = useState("n/a");
	const [profile2ndLine, setProfile2ndLine] = useState("n/a");

	useEffect(() => {
		adindexService
			.getPublisherInformation(
				JSON.stringify({
					publisherId: jwtDecode(localStorage.getItem(AUTH_TOKEN)).userId,
				})
			)
			.then((resp) => {
				if (resp.data.error) {
					message.error(resp.data.error.message);
				} else {
					const publisherData = resp.data.items[0];
					localStorage.setItem("publisherData", JSON.stringify(publisherData));

					setProfile2ndLine(publisherData["email"]);

					if (publisherData["company"] && publisherData["company"] !== "") {
						setProfile1stLine(publisherData["company"]);
					} else if (
						publisherData["firstname"] &&
						publisherData["firstname"] !== "" &&
						publisherData["lastname"] &&
						publisherData["lastname"] !== ""
					) {
						setProfile1stLine(
							publisherData["firstname"] + " " + publisherData["lastname"]
						);
					} else {
						setProfile1stLine("Profile incomplete");
					}
				}
			});
	}, []);

	const profileMenu = (
		<div className="nav-profile nav-dropdown">
			<div className="nav-profile-header">
				<div className="d-flex">
					<Avatar
						size={45}
						icon={<UserOutlined />}
						style={{ backgroundColor: "#FF601C" }}
					/>
					<div className="pl-3">
						<h4 className="mb-0">{profile1stLine}</h4>
						<span className="text-muted">{profile2ndLine}</span>
					</div>
				</div>
			</div>
			<div className="nav-profile-body">
				<Menu>
					{menuItem.map((el, i) => {
						return (
							<Menu.Item key={i}>
								<a href={el.path}>
									<Icon className="mr-3" type={el.icon} />
									<span className="font-weight-normal">{el.title}</span>
								</a>
							</Menu.Item>
						);
					})}
					<Menu.Item key={menuItem.length + 1} onClick={(e) => signOut()}>
						<span>
							<LogoutOutlined className="mr-3" />
							<span className="font-weight-normal">Sign Out</span>
						</span>
					</Menu.Item>
				</Menu>
			</div>
		</div>
	);
	return (
		<Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
			<Menu className="d-flex align-item-center" mode="horizontal">
				<Menu.Item key="profile">
					<Avatar
						icon={<UserOutlined />}
						style={{ backgroundColor: "#FF601C" }}
					/>
				</Menu.Item>
			</Menu>
		</Dropdown>
	);
};

export default connect(null, { signOut })(NavProfile);
