import React from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import Views from "./views";
import { Route, Switch } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG, AUTH_PREFIX_PATH } from "./configs/AppConfig";
//import TagManager from "react-gtm-module";
import PiwikPro from '@piwikpro/react-piwik-pro';

const themes = {
    dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
    light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};
//const tagManagerArgs = {
//	gtmId: "GTM-5LMXB3NK",
//};

//TagManager.initialize(tagManagerArgs);

const PiwikInitializer = () => {
    const location = useLocation();
    const hasInitialized = React.useRef(false);
    const excludedRoutes = [`${AUTH_PREFIX_PATH}/redirect-error`]

    React.useEffect(() => {
        if (!hasInitialized.current && !excludedRoutes.includes(location.pathname)) {
            PiwikPro.initialize('8458ec89-3261-44dd-8550-390ad69a2783', 'https://adindex.piwik.pro');
            hasInitialized.current = true;
        }
    }, [location]);

    return null;
};

function App() {
    return (
        <div className="App">
            <Provider store={store}>
                <ThemeSwitcherProvider
                    themeMap={themes}
                    defaultTheme={THEME_CONFIG.currentTheme}
                    insertionPoint="styles-insertion-point"
                >
                    <Router>
                        <PiwikInitializer />
                        <Switch>
                            <Route path="/" component={Views} />
                        </Switch>
                    </Router>
                </ThemeSwitcherProvider>
            </Provider>
        </div>
    );
}

export default App;
